import bus from './bus';

const setMenuList = function (nowMenu){
  let menuList = window.localStorage.getItem("menuList");
  if(menuList) menuList = JSON.parse(menuList);
  menuList.push(nowMenu)
  window.localStorage.setItem('menuList',JSON.stringify(menuList));
  bus.$emit("getMenuList")
}

const resetMenuList = function (menuList){
  window.localStorage.setItem('menuList',JSON.stringify(menuList));
  bus.$emit("getMenuList")
}

export {setMenuList,resetMenuList}
