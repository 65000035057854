<template>
  <div>
    <div class="table-page-title">
      <div class="fl">
        <el-form :model="searchForm" class="search-form" :inline="true">
          <el-form-item label="" prop="name">
            <el-input class="small-input" v-model="searchForm.name" placeholder="真实姓名"></el-input>
          </el-form-item>
          <el-form-item label="" prop="phone">
            <el-input class="small-input" v-model="searchForm.phone" placeholder="联系电话"></el-input>
          </el-form-item>
          <el-form-item label="" prop="roleId">
            <el-select clearable class="small-input" v-model="searchForm.roleId" placeholder="角色">
              <el-option
                  v-for="item in roleList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="fl search-form-btn">
        <el-button class="fl" size="small" type="primary" @click="search()">搜 索</el-button>
        <el-button class="fl" size="small" @click="reset()">重 置</el-button>
      </div>
      <div class="ft">
        <el-button size="small" type="primary" @click="goUpdateStaff()">新 增</el-button>
        <el-button size="small" type="danger" @click="deleteStaffs()" :loading="deleteLoading">删 除
        </el-button>
      </div>
    </div>
    <div class="table-container">
      <el-table
          class="table"
          height="100%"
          v-loading="tableLoading"
          @selection-change="handleSelectionChange"
          :data="tableData">
        <el-table-column
            type="selection"
            align="center"
            header-align="center"
            width="55">
        </el-table-column>
        <el-table-column
            label="员工编号"
            prop="code"
            align="center"
            header-align="center"
        >
          <template slot-scope="scope">
            <span class="link_word"  @click="goUpdateStaff(scope.row.id)">{{ scope.row.code ? scope.row.code : '-' }}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="真实姓名"
            prop="name"
            align="center"
            header-align="center"
            :formatter="commonFormatter"
        >
        </el-table-column>
        <el-table-column
            label="联系电话"
            prop="phone"
            align="center"
            header-align="center"
            :formatter="commonFormatter"
        >
        </el-table-column>
        <el-table-column
            label="状态"
            width="100"
            align="center"
            header-align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.state === 1 ? '有效' : scope.row.state === 0 ? '注销' : '-' }}
          </template>
        </el-table-column>
        <el-table-column
            label="创建时间"
            align="center"
            header-align="center"
            prop="createTime"
            :formatter="commonFormatter"
        >
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
        class="top_10 right right_10"
        :current-page="pageNum"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
    </el-pagination>
  </div>
</template>

<script>
import tableUse from "@/mixins/tableUse";
import {setMenuList} from "@/assets/js/common";

export default {
  name: "StaffList",
  mixins: [tableUse],
  data() {
    return {
      searchForm: {
        name: '',
        phone: '',
        roleId: ''
      },
      roleList: [],
      tableData: [],
      editRow: null,
      deleteLoading: false
    }
  },
  methods: {
    goUpdateStaff(id){
      let url = {
        name:'AddStaff',
        query:{

        }
      }
      if(id) url.query.id = id;
      setMenuList({
        path:'/orderDetail',
        name:id ? '员工详情' : '新增员工'
      })
      this.$router.push(url)
    },
    deleteStaffs() {
      if (this.selectList.length === 0) {
        this.$message.error("请选择要删除的员工");
        return
      }
      this.$confirm('确认删除员工？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal:false,
        closeOnPressEscape:false,
        type: 'warning'
      }).then(() => {
        var ids = [];
        this.selectList.forEach((item) => {
          ids.push(item.id);
        });
        ids = ids.join(",");
        this.deleteLoading = true;
        this.$axios.post("/sys-api/employee-api/deleteEmployee?ids=" + ids, {}, (res) => {
          this.deleteLoading = false;
          if (res.code === '100') {
            this.$message.success("删除成功");
            this.getTableData();
          }
        })
      })
    },
    getRoleList() {
      this.$axios.get("/sys-api/role-api/roleList", {}, (res) => {
        if (res.code === '100') {
          this.roleList = res.data;
        }
      })
    },
    reset() {
      this.searchForm = {
        name: '',
        phone: '',
        roleId: ''
      }
      this.search();
    },
    getTableData() {
      this.tableLoading = true;
      let formData = {
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      if (this.searchForm.name) formData.name = this.searchForm.name;
      if (this.searchForm.phone) formData.phone = this.searchForm.phone;
      if (this.searchForm.roleId) formData.roleId = this.searchForm.roleId;
      this.$axios.post("/sys-api/employee-api/searchEmployeePage", formData, (res) => {
        this.tableLoading = false;
        if (res.code === '100') {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      })
    }
  },
  mounted() {
    this.getRoleList();
    this.getTableData();
  }
}
</script>

<style scoped lang="scss">
</style>
